<template>
  <div class="box-graphics">
    <div class="d-flex justify-end">
      <seguimiento class="ml-5" @reloadEfficiencyCharts="reloadCharts()"></seguimiento>
    </div>
    <h3 class="text-center tway-header-1">Eficiencia</h3>
    <!-- HERRAMIENTAS & METODOLOGÍAS -->
    <div class="graphics-container-cuatriple">
      <div class="tway-violet--text">Herramientas Digitales</div>
      <!-- HERRAMIENTAS DIGITALES -->
      <div>
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Reducción de Costos en Procesos
              <ToolTips
                width="300"
                description="Disminución en el costo de produción de una unidad de producto en un periodo de tiempo por el uso de herramientas digitales."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              ref="chartCostReduction"
              :series="chartCostReduction.series"
              :options="chartCostReduction.options"
              width="500"
              height="300"
              type="bar"
            />
          </div>
        </div>
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Reducción de Tareas
              <ToolTips
                width="270"
                description="Disminución en cantidad de acciones y tareas por el uso de herramientas digitales."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              ref="chartTaskReduction"
              :series="chartTaskReduction.series"
              :options="chartTaskReduction.options"
              width="500"
              height="300"
              type="bar"
            />
          </div>
        </div>
      </div>
      <!-- METODOLOGÍAS ÁGILES  -->
      <div class="tway-violet--text mt-4">Metodologías Ágiles</div>
      <div>
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Disminución CAC
              <ToolTips width="250" description="Disminuir el costo de adquisición por cada nuevo cliente." />
            </p>
          </div>
          <div>
            <VueApexCharts
              :series="chartCacReduction.series"
              :options="chartCacReduction.chartOptions"
              height="290"
              type="radialBar"
            />
          </div>
        </div>
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Mejora LTV
              <ToolTips
                width="250"
                description="Atribución a metodologías ágiles en el incremento del indicador LTV (Valor de vida del cliente)."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              :series="chartLtvImprovement.series"
              :options="chartLtvImprovement.chartOptions"
              height="290"
              type="radialBar"
            />
          </div>
        </div>
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Mejora Time to Market
              <ToolTips
                width="310"
                description="Reducción en el tiempo de comercialización de un producto o servicio a causa de implementación de metodologías ágiles."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              :series="chartTimeMarketImprovement.series"
              :options="chartTimeMarketImprovement.chartOptions"
              height="290"
              type="radialBar"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- CANALES DIGITALES -->
    <div class="graphics-container-triple">
      <div class="tway-violet--text">Canales Digitales</div>
      <div>
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Tasa de Alcance
              <ToolTips width="330" description="Número de usuarios únicos que ve la campaña digital comunicada." />
            </p>
          </div>
          <div>
            <VueApexCharts
              ref="chartReachRate"
              :series="chartReachRate.series"
              :options="chartReachRate.options"
              height="300"
              type="area"
            />
          </div>
        </div>
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Tasa de Conversión
              <ToolTips
                width="330"
                description="Cantidad de usuarios que realiza una acción específica (uso, clic, descarga, registro, etc.) a través de un canal digital."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              ref="chartDigitalSale"
              :series="chartDigitalSale.series"
              :options="chartDigitalSale.options"
              height="300"
              type="area"
            />
          </div>
        </div>
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Tasa de Ventas
              <ToolTips
                width="330"
                description="Cantidad de usuarios que realiza una compra online."
                formula="(Cantidad de usuarios del canal digital) / (Cantidad de usuarios que compraron)"
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              ref="chartDigitalChannel"
              :series="chartDigitalChannel.series"
              :options="chartDigitalChannel.options"
              height="300"
              type="area"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- EFICIENCIA DE TIEMPOS -->
    <div class="graphics-container-cuatriple">
      <div class="tway-violet--text">Eficiencia de Tiempos</div>
      <div>
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p class="mx-2">
              Desviación de Tiempo en Procesos Críticos
              <ToolTips
                width="350"
                description="Diferencia entre tiempo estimado y tiempo real empleado en la resolución de procesos vitales y determinantes para el modelo de negocio y que se bordan con máxima prioridad."
                formula="(Tiempo empleado – Tiempo planificado) x 100 / Tiempo planificado."
                objective="Objetivo del KPI: ≤ 0%."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              ref="chartTimeDeviation"
              :series="chartTimeDeviation.series"
              :options="chartTimeDeviation.options"
              width="500"
              height="300"
              type="bar"
            />
          </div>
        </div>
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p class="mx-2">
              Detección Temprana de Riesgos de Datos
              <ToolTips
                width="300"
                description="Tiempo en minutos que toma identificar y alertar en el momento en que ocurre un evento que pone en riesgo la data de una empresa."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              ref="chartEarlyDetection"
              :series="chartEarlyDetection.series"
              :options="chartEarlyDetection.options"
              width="500"
              height="300"
              type="bar"
            />
          </div>
        </div>
      </div>
      <div class="mt-2">
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p class="mx-2 pe-6">
              Respuesta por Manejo de Datos
              <ToolTips
                width="300"
                description="Tiempo en minutos que toma identificar y alertar en los primeros minutos en que ocurre un evento que pone en riesgo la data de una empresa."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              ref="chartQuickResponse"
              :series="chartQuickResponse.series"
              :options="chartQuickResponse.options"
              width="500"
              height="300"
              type="bar"
            />
          </div>
        </div>
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text text-left">
            <p>
              Resolución en Primera Línea
              <ToolTips
                width="270"
                description="Relación entre cantidad de llamadas a un centro de atención y su solución por los agentes a cargo en el primer contacto."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              ref="chartFirstLine"
              :series="chartFirstLine.series"
              :options="chartFirstLine.options"
              width="500"
              height="300"
              type="bar"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Seguimiento from "../../components/pmo/efficiency/seguimiento.vue";
import EfficiencyService from "../../services/pmo/efficiency/efficiencyService";
import BudgetService from "@/services/pmo/budget/budgetService";
import VueApexCharts from "vue-apexcharts";
import ToolTips from "../../components/pmo/utils/ToolTips";

export default {
  name: "Engagement",
  components: {
    Seguimiento,
    VueApexCharts,
    ToolTips,
  },
  data() {
    return {
      monetaryUnit: "",
      chartCostReduction: {
        series: [],
        options: {
          chart: {
            height: 450,
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "bar",
            stacked: false,
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 3,
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            lineCap: "round",
            width: [2],
            curve: "smooth",
            dashArray: [0],
          },
          markers: { size: 3 },
          title: {
            text: undefined,
          },
          grid: {
            row: {
              colors: ["#f3f3f3"],
            },
          },
          xaxis: {
            categories: [],
          },
          colors: ["#7319D5"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#7319D5",
              },
              labels: { style: { colors: "#7319D5" } },
              title: {
                text: "",
                style: { color: "#7319D5" },
              },
              min: 0,
              tooltip: { enabled: true },
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60,
            },
          },
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },
      chartTaskReduction: {
        series: [],
        options: {
          chart: {
            height: 450,
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "area",
            stacked: false,
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 3,
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            width: [2],
            curve: "smooth",
            dashArray: [0],
          },
          markers: { size: 3 },
          title: {
            text: undefined,
          },
          grid: {
            row: {
              colors: ["#f3f3f3"],
            },
          },
          xaxis: {
            categories: [],
          },
          colors: ["#16C62E"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#16C62E",
              },
              labels: { style: { colors: "#16C62E" } },
              title: {
                text: "Cantidad de Acciones",
                style: { color: "#16C62E" },
              },
              min: 0,
              tooltip: { enabled: true },
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60,
            },
          },
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },
      chartCacReduction: {
        series: [],
        chartOptions: {
          chart: {
            type: "radialBar",
            fontFamily: "Josefin Sans, Arial, sans-serif",
            offsetY: -20,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              hollow: {
                margin: 0,
                size: "55%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
              },
              track: {
                background: "#e7e7e7",
                strokeWidth: "97%",
                margin: 5, // margin is in pixels

                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: "#fff",
                  opacity: 1,
                  blur: 2,
                },
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -2,
                  fontSize: "30px",
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: "solid",
            colors: ["#7319D5"],
          },
          labels: ["Average Results"],
          stroke: { lineCap: "round" },
        },
      },
      chartLtvImprovement: {
        series: [],
        chartOptions: {
          chart: {
            type: "radialBar",
            fontFamily: "Josefin Sans, Arial, sans-serif",
            offsetY: -20,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              hollow: {
                margin: 0,
                size: "55%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
              },
              track: {
                background: "#e7e7e7",
                strokeWidth: "97%",
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: "#fff",
                  opacity: 1,
                  blur: 2,
                },
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -2,
                  fontSize: "30px",
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: "solid",
            colors: ["#16C62E"],
          },
          labels: ["Average Results"],
          stroke: { lineCap: "round" },
        },
      },
      chartTimeMarketImprovement: {
        series: [],
        chartOptions: {
          chart: {
            type: "radialBar",
            fontFamily: "Josefin Sans, Arial, sans-serif",
            offsetY: -20,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              hollow: {
                margin: 0,
                size: "55%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
              },
              track: {
                background: "#e7e7e7",
                strokeWidth: "97%",
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: "#fff",
                  opacity: 1,
                  blur: 2,
                },
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -2,
                  fontSize: "30px",
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: "solid",
            colors: ["#7F7F7F"],
          },
          labels: ["Average Results"],
          stroke: { lineCap: "round" },
        },
      },

      chartReachRate: {
        series: [],
        options: {
          chart: {
            height: 450,
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "area",
            stacked: false,
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            width: [2],
            curve: "smooth",
            dashArray: [0],
          },
          markers: { size: 3 },
          title: {
            text: undefined,
          },
          grid: {
            row: {
              colors: ["#f3f3f3"],
            },
          },
          xaxis: {
            categories: [],
          },
          colors: ["#7319D5"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#7319D5",
              },
              labels: { style: { colors: "#7319D5" } },
              title: {
                text: "Cantidad de Usuarios",
                style: { color: "#7319D5" },
              },
              min: 0,
              tooltip: { enabled: true },
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60,
            },
          },
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },
      chartDigitalSale: {
        series: [],
        options: {
          chart: {
            height: 450,
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "area",
            stacked: false,
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            width: [2],
            curve: "smooth",
            dashArray: [0],
          },
          markers: { size: 3 },
          title: {
            text: undefined,
          },
          grid: {
            row: {
              colors: ["#f3f3f3"],
            },
          },
          xaxis: {
            categories: [],
          },
          colors: ["#16C62E"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#16C62E",
              },
              labels: { style: { colors: "#16C62E" } },
              title: {
                text: "Cantidad de Usuarios",
                style: { color: "#16C62E" },
              },
              min: 0,
              tooltip: { enabled: true },
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60,
            },
          },
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },
      chartDigitalChannel: {
        series: [],
        options: {
          chart: {
            height: 450,
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "area",
            stacked: false,
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            width: [2],
            curve: "smooth",
            dashArray: [0],
          },
          markers: { size: 3 },
          title: {
            text: undefined,
          },
          grid: {
            row: {
              colors: ["#f3f3f3"],
            },
          },
          xaxis: {
            categories: [],
          },
          colors: ["#7F7F7F"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#7F7F7F",
              },
              labels: { style: { colors: "#7F7F7F" } },
              title: {
                text: "Cantidad de Usuarios",
                style: { color: "#7F7F7F" },
              },
              min: 0,
              tooltip: { enabled: true },
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60,
            },
          },
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },

      chartTimeDeviation: {
        series: [],
        options: {
          chart: {
            height: 450,
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "area",
            stacked: false,
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 3,
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            width: [2],
            curve: "smooth",
            dashArray: [0],
          },
          markers: { size: 3 },
          title: {
            text: undefined,
          },
          grid: {
            row: {
              colors: ["#f3f3f3"],
            },
          },
          xaxis: {
            categories: [],
          },
          // colors: [
          //   "#7319D5",
          //   "#B2B2B2",
          //   "#AEEA00",
          //   "#4A148C",
          //   "#7F7F7F",
          //   "#16C62E",
          // ],
          colors: ["#7319D5"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#7319D5",
              },
              labels: { style: { colors: "#7319D5" } },
              title: {
                text: "Minutos",
                style: { color: "#7319D5" },
              },
              min: 0,
              tooltip: { enabled: true },
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60,
            },
          },
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },
      chartEarlyDetection: {
        series: [],
        options: {
          chart: {
            height: 450,
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "area",
            stacked: false,
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 3,
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            width: [2],
            curve: "smooth",
            dashArray: [0],
          },
          markers: { size: 3 },
          title: {
            text: undefined,
          },
          grid: {
            row: {
              colors: ["#f3f3f3"],
            },
          },
          xaxis: {
            categories: [],
          },
          colors: ["#16C62E"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#16C62E",
              },
              labels: { style: { colors: "#16C62E" } },
              title: {
                text: "Minutos",
                style: { color: "#16C62E" },
              },
              min: 0,
              tooltip: { enabled: true },
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60,
            },
          },
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },
      chartQuickResponse: {
        series: [],
        options: {
          chart: {
            height: 450,
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "area",
            stacked: false,
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 3,
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            width: [2],
            curve: "smooth",
            dashArray: [0],
          },
          markers: { size: 3 },
          title: {
            text: undefined,
          },
          grid: {
            row: {
              colors: ["#f3f3f3"],
            },
          },
          xaxis: {
            categories: [],
          },
          colors: ["#7F7F7F"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#7F7F7F",
              },
              labels: { style: { colors: "#7F7F7F" } },
              title: {
                text: "Minutos",
                style: { color: "#7F7F7F" },
              },
              min: 0,
              tooltip: { enabled: true },
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60,
            },
          },
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },
      chartFirstLine: {
        series: [],
        options: {
          chart: {
            height: 450,
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "area",
            stacked: false,
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 3,
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            width: [2],
            curve: "smooth",
            dashArray: [0],
          },
          markers: { size: 3 },
          title: {
            text: undefined,
          },
          grid: {
            row: {
              colors: ["#f3f3f3"],
            },
          },
          xaxis: {
            categories: [],
          },
          colors: ["#AEEA00"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#AEEA00",
              },
              labels: { style: { colors: "#AEEA00" } },
              title: {
                text: "%",
                style: { color: "#AEEA00" },
              },
              min: 0,
              tooltip: { enabled: true },
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60,
            },
          },
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },
    };
  },

  methods: {
    reloadCharts() {
      this.callService();
    },
    async callService() {
      await EfficiencyService.getChartDigitalTools(this.project)
        .then((data) => {
          this.chartCostReduction.series = data.cost;
          this.chartTaskReduction.series = data.tasks;
          this.$refs.chartCostReduction.updateOptions({
            xaxis: {
              categories: data.cost[0].dates,
            },
            yaxis: [{ title: { text: this.monetaryUnit } }],
          });
          this.$refs.chartTaskReduction.updateOptions({
            xaxis: {
              categories: data.tasks[0].dates,
            },
          });
        })
        .catch((err) => console.dir(err));
      await EfficiencyService.getChartAgileMethodology(this.project)
        .then((data) => {
          this.chartCacReduction.series = data.cac.data;
          this.chartLtvImprovement.series = data.ltv.data;
          this.chartTimeMarketImprovement.series = data.market.data;
        })
        .catch((err) => console.dir(err));
      await EfficiencyService.getChartDigitalChannels(this.project)
        .then((data) => {
          this.chartReachRate.series = data.reach;
          this.chartDigitalSale.series = data.sale;
          this.chartDigitalChannel.series = data.channel;
          this.$refs.chartReachRate.updateOptions({
            xaxis: {
              categories: data.reach[0].dates,
            },
          });
          this.$refs.chartDigitalSale.updateOptions({
            xaxis: {
              categories: data.sale[0].dates,
            },
          });
          this.$refs.chartDigitalChannel.updateOptions({
            xaxis: {
              categories: data.channel[0].dates,
            },
          });
        })
        .catch((err) => console.dir(err));

      await EfficiencyService.getChartTimeEfficiency(this.project)
        .then((data) => {
          this.chartTimeDeviation.series = data.deviation;
          this.chartEarlyDetection.series = data.detection;
          this.chartQuickResponse.series = data.response;
          this.chartFirstLine.series = data.firstLine;
          this.$refs.chartTimeDeviation.updateOptions({
            xaxis: {
              categories: data.deviation[0].dates,
            },
          });
          this.$refs.chartEarlyDetection.updateOptions({
            xaxis: {
              categories: data.detection[0].dates,
            },
          });
          this.$refs.chartQuickResponse.updateOptions({
            xaxis: {
              categories: data.response[0].dates,
            },
          });
          this.$refs.chartFirstLine.updateOptions({
            xaxis: {
              categories: data.firstLine[0].dates,
            },
          });
        })
        .catch((err) => console.dir(err));
    },
    getMonetaryUnit() {
      BudgetService.getTableCostCenter(this.project)
        .then((data) => {
          this.monetaryUnit = data.monetaryUnit;
        })
        .catch((err) => console.dir(err));
    },
  },
  async mounted() {
    let pmoProjectIds = JSON.parse(sessionStorage.getItem("pmoProjectIds"));
    this.project = pmoProjectIds;

    this.callService();
    this.getMonetaryUnit();
  },
};
</script>

<style lang="scss" scoped>
.box-graphics {
  max-width: 1400px;
  min-width: 1100px;
}

.graphics {
  display: flex;
  flex-direction: column;
}

.shrink {
  width: 150px;
}

.bar {
  width: 350px;
}

.resumen {
  display: flex;
  width: 100%;
  height: 250px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  justify-content: center;
  margin: 25px 0px 25px 0px;
}

.resumen > .resumen-detail {
  width: 17%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.resumen > .resumen-detail > .resumen-detail-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.resumen > .resumen-detail > .resumen-detail-item > div:first-child {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 55%;
}

.resumen > .resumen-detail > .resumen-detail-item > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 15%;
}

.resumen > .resumen-bar {
  display: flex;
  width: 60%;
  height: auto;
}

.resumen-title {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: solid black 1px;
  justify-content: space-between;
}

.resumen-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: solid black 1px;
  justify-content: space-between;
  margin-bottom: 15px;
}

.seguimiento {
  width: 100%;
  display: flex;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  justify-content: center;
  margin: 25px 0px 25px 0px;
  height: 500px;
}

.seguimiento-costo {
  width: 45%;
  margin: 2px;
}

.seguimiento-periodo {
  width: 45%;
  margin: 2px;
}

.graphics-container {
  display: flex;
  flex-direction: column;
  align-items: space-evenly;
  width: 100%;
  padding: 50px 20px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 20px;
  margin: 25px 0px 25px 0px;
  div {
    display: flex;
    flex-direction: column;
  }
  p {
    font-size: 16px;
  }
}

.graphics-container-duo,
.graphics-container-triple,
.graphics-container-cuatriple {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  padding: 30px 20px 20px 20px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 20px;
  margin: 25px 0px 25px 0px;
  div {
    display: flex;
    flex-direction: row;
  }
  p {
    font-size: 16px;
  }
}

.graphics-container-duo {
  div {
    div {
      display: flex;
      flex-direction: column;
      width: 47%;

      div {
        width: 100%;
      }

      //border: 1px solid black;
    }
  }
}

.graphics-container-triple {
  div {
    div {
      width: 33%;
      display: flex;
      flex-direction: column;
      div {
        width: 100%;
      }

      //border: 1px solid black;
    }
  }
}
.graphics-container-cuatriple {
  div {
    div {
      width: 50%;
      display: flex;
      flex-direction: column;
      div {
        width: 100%;
      }

      //border: 1px solid black;
    }
  }
}
</style>
